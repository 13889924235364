import { createTheme } from "@mui/material";

const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blue: "#242C59",
  whiteBlue: "#F0F5FF",
};

const palette = createTheme({
  palette: {
    primary: {
      main: colors.blue,
      black: colors.black,
      white: colors.white,
    },
    secondary: {
      main: colors.whiteBlue,
    },
  },
});

const breakpoints = createTheme({});

export const mainTheme = createTheme(palette, {
  typography: {
    h1: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
    },
    h2: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
    },
    h3: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "900",
      fontSize: "30px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "36px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "36px",
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: "50px",
      },
    },
    h4: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "900",
      fontSize: "24px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "36px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: "32px",
      },
      [breakpoints.breakpoints.up("xl")]: {
        fontSize: "40px",
      },
    },
    h5: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "900",
      fontSize: "22px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: "30px",
      },
    },
    h6: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "900",
      fontSize: "16px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "20px",
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: "28px",
      },
    },
    subtitle1: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "18px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "15px",
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: "15px",
      },
    },
    subtitle2: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "12px",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "12px",
      },
    },
    body1: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "10px",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "12px",
      },
    },
    body2: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "18px",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [breakpoints.breakpoints.up("md")]: {
        fontSize: "20px",
      },
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "15px 20px",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            borderRadius: "20px",
            textTransform: "none",
            boxShadow: "none",
            minWidth: 0,
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 20px",
          maxWidth: `${breakpoints.breakpoints.values.lg}px !important`,
          [breakpoints.breakpoints.up("md")]: {
            padding: "0 40px",
          },
          [breakpoints.breakpoints.up("xl")]: {
            padding: "0",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "20px !important",
          backgroundColor: palette.palette.primary.white,
          borderColor: "transparent",
        },
      },
    },
  },
});
